import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["slots", "slotProps", "apiRef"];
export const extractPluginParamsFromProps = _ref => {
  let {
      props: {
        slots,
        slotProps,
        apiRef
      },
      plugins,
      rootRef
    } = _ref,
    props = _objectWithoutPropertiesLoose(_ref.props, _excluded);
  const paramsLookup = {};
  plugins.forEach(plugin => {
    Object.assign(paramsLookup, plugin.params);
  });
  const pluginParams = {
    plugins,
    rootRef,
    slots: slots ?? {},
    slotProps: slotProps ?? {},
    apiRef
  };
  const otherProps = {};
  Object.keys(props).forEach(propName => {
    const prop = props[propName];
    if (paramsLookup[propName]) {
      pluginParams[propName] = prop;
    } else {
      otherProps[propName] = prop;
    }
  });
  return {
    pluginParams,
    slots,
    slotProps,
    otherProps
  };
};