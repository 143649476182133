import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { extractEventHandlers } from '@mui/base/utils';
import useForkRef from '@mui/utils/useForkRef';
import { useTreeViewContext } from '../internals/TreeViewProvider/useTreeViewContext';
import { useTreeItem2Utils } from '../hooks/useTreeItem2Utils';
export const useTreeItem2 = parameters => {
  const {
    runItemPlugins,
    selection: {
      multiSelect,
      disableSelection,
      checkboxSelection
    },
    disabledItemsFocusable,
    instance,
    publicAPI
  } = useTreeViewContext();
  const {
    id,
    itemId,
    label,
    children,
    rootRef
  } = parameters;
  const {
    rootRef: pluginRootRef,
    contentRef
  } = runItemPlugins(parameters);
  const {
    interactions,
    status
  } = useTreeItem2Utils({
    itemId,
    children
  });
  const idAttribute = instance.getTreeItemIdAttribute(itemId, id);
  const handleRootRef = useForkRef(rootRef, pluginRootRef);
  const checkboxRef = React.useRef(null);
  const createRootHandleFocus = otherHandlers => event => {
    otherHandlers.onFocus?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    const canBeFocused = !status.disabled || disabledItemsFocusable;
    if (!status.focused && canBeFocused && event.currentTarget === event.target) {
      instance.focusItem(event, itemId);
    }
  };
  const createRootHandleBlur = otherHandlers => event => {
    otherHandlers.onBlur?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    instance.removeFocusedItem();
  };
  const createRootHandleKeyDown = otherHandlers => event => {
    otherHandlers.onKeyDown?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    instance.handleItemKeyDown(event, itemId);
  };
  const createContentHandleClick = otherHandlers => event => {
    otherHandlers.onClick?.(event);
    if (event.defaultMuiPrevented || checkboxRef.current?.contains(event.target)) {
      return;
    }
    interactions.handleExpansion(event);
    if (!checkboxSelection) {
      interactions.handleSelection(event);
    }
  };
  const createContentHandleMouseDown = otherHandlers => event => {
    otherHandlers.onMouseDown?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }

    // Prevent text selection
    if (event.shiftKey || event.ctrlKey || event.metaKey || status.disabled) {
      event.preventDefault();
    }
  };
  const createCheckboxHandleChange = otherHandlers => event => {
    otherHandlers.onChange?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    if (disableSelection || status.disabled) {
      return;
    }
    interactions.handleCheckboxSelection(event);
  };
  const getRootProps = (externalProps = {}) => {
    const externalEventHandlers = _extends({}, extractEventHandlers(parameters), extractEventHandlers(externalProps));
    let ariaSelected;
    if (multiSelect) {
      ariaSelected = status.selected;
    } else if (status.selected) {
      /* single-selection trees unset aria-selected on un-selected items.
       *
       * If the tree does not support multiple selection, aria-selected
       * is set to true for the selected item and it is not present on any other item in the tree.
       * Source: https://www.w3.org/WAI/ARIA/apg/patterns/treeview/
       */
      ariaSelected = true;
    }
    return _extends({}, externalEventHandlers, {
      ref: handleRootRef,
      role: 'treeitem',
      tabIndex: instance.canItemBeTabbed(itemId) ? 0 : -1,
      id: idAttribute,
      'aria-expanded': status.expandable ? status.expanded : undefined,
      'aria-selected': ariaSelected,
      'aria-disabled': status.disabled || undefined
    }, externalProps, {
      onFocus: createRootHandleFocus(externalEventHandlers),
      onBlur: createRootHandleBlur(externalEventHandlers),
      onKeyDown: createRootHandleKeyDown(externalEventHandlers)
    });
  };
  const getContentProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    return _extends({}, externalEventHandlers, externalProps, {
      ref: contentRef,
      onClick: createContentHandleClick(externalEventHandlers),
      onMouseDown: createContentHandleMouseDown(externalEventHandlers),
      status
    });
  };
  const getCheckboxProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    return _extends({}, externalEventHandlers, {
      visible: checkboxSelection,
      ref: checkboxRef,
      checked: status.selected,
      disabled: disableSelection || status.disabled,
      tabIndex: -1
    }, externalProps, {
      onChange: createCheckboxHandleChange(externalEventHandlers)
    });
  };
  const getLabelProps = (externalProps = {}) => {
    const externalEventHandlers = _extends({}, extractEventHandlers(parameters), extractEventHandlers(externalProps));
    return _extends({}, externalEventHandlers, {
      children: label
    }, externalProps);
  };
  const getIconContainerProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    return _extends({}, externalEventHandlers, externalProps);
  };
  const getGroupTransitionProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    return _extends({}, externalEventHandlers, {
      unmountOnExit: true,
      component: 'ul',
      role: 'group',
      in: status.expanded,
      children
    }, externalProps);
  };
  return {
    getRootProps,
    getContentProps,
    getGroupTransitionProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    rootRef: handleRootRef,
    status,
    publicAPI
  };
};