import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import useEventCallback from '@mui/utils/useEventCallback';
export const useTreeViewExpansion = ({
  instance,
  params,
  models
}) => {
  const expandedItemsMap = React.useMemo(() => {
    const temp = new Map();
    models.expandedItems.value.forEach(id => {
      temp.set(id, true);
    });
    return temp;
  }, [models.expandedItems.value]);
  const setExpandedItems = (event, value) => {
    params.onExpandedItemsChange?.(event, value);
    models.expandedItems.setControlledValue(value);
  };
  const isItemExpanded = React.useCallback(itemId => expandedItemsMap.has(itemId), [expandedItemsMap]);
  const isItemExpandable = React.useCallback(itemId => !!instance.getItemMeta(itemId)?.expandable, [instance]);
  const toggleItemExpansion = useEventCallback((event, itemId) => {
    const isExpandedBefore = instance.isItemExpanded(itemId);
    instance.setItemExpansion(event, itemId, !isExpandedBefore);
  });
  const setItemExpansion = useEventCallback((event, itemId, isExpanded) => {
    const isExpandedBefore = instance.isItemExpanded(itemId);
    if (isExpandedBefore === isExpanded) {
      return;
    }
    let newExpanded;
    if (isExpanded) {
      newExpanded = [itemId].concat(models.expandedItems.value);
    } else {
      newExpanded = models.expandedItems.value.filter(id => id !== itemId);
    }
    if (params.onItemExpansionToggle) {
      params.onItemExpansionToggle(event, itemId, isExpanded);
    }
    setExpandedItems(event, newExpanded);
  });
  const expandAllSiblings = (event, itemId) => {
    const itemMeta = instance.getItemMeta(itemId);
    const siblings = instance.getItemOrderedChildrenIds(itemMeta.parentId);
    const diff = siblings.filter(child => instance.isItemExpandable(child) && !instance.isItemExpanded(child));
    const newExpanded = models.expandedItems.value.concat(diff);
    if (diff.length > 0) {
      if (params.onItemExpansionToggle) {
        diff.forEach(newlyExpandedItemId => {
          params.onItemExpansionToggle(event, newlyExpandedItemId, true);
        });
      }
      setExpandedItems(event, newExpanded);
    }
  };
  return {
    publicAPI: {
      setItemExpansion
    },
    instance: {
      isItemExpanded,
      isItemExpandable,
      setItemExpansion,
      toggleItemExpansion,
      expandAllSiblings
    }
  };
};
useTreeViewExpansion.models = {
  expandedItems: {
    getDefaultValue: params => params.defaultExpandedItems
  }
};
const DEFAULT_EXPANDED_ITEMS = [];
useTreeViewExpansion.getDefaultizedParams = params => _extends({}, params, {
  defaultExpandedItems: params.defaultExpandedItems ?? DEFAULT_EXPANDED_ITEMS
});
useTreeViewExpansion.params = {
  expandedItems: true,
  defaultExpandedItems: true,
  onExpandedItemsChange: true,
  onItemExpansionToggle: true
};