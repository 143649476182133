import "mapbox-gl/dist/mapbox-gl.css";
import "../../../style.css";

import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Grid from "@mui/material/Unstable_Grid2";
import { CircularProgress } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import {
    gebcoColorMap,
    getFilteredCatalog,
} from "../../utils/Map";
import MapLayerMenu from "../../components/MapLayerMenu";
import DocumentTable from "../../components/DocumentTable";
import DocumentDescription from "../../components/DocumentDescription";
import ICatalogEntry from "../../types/ICatalogEntry";
import MenuAppBar from "../../components/MenuAppBar";
import { MapConfig } from "./MapConfig";
import ColorLegend from "../../components/ColorLegend";

mapboxgl.accessToken =
    "pk.eyJ1Ijoic2lib3dpIiwiYSI6ImNsczh6YjJkcTAxZjkybm9qeXRzNXg4aWkifQ.ZPA7hKSqRhnprJzPKXU-Zw";



export function MapPage() {
    // Catalog specifying all documents
    const pathCatalog = new URL(
        "../../../resources/document_catalog_version10.csv",
        import.meta.url
    );
    const [catalog, setCatalog] = useState<ICatalogEntry[]>();
    // All layers
    const [allDocumentLayerIds, setAllDocumentLayerIds] = useState<string[]>([]);
    // Currently visible/rendered layers
    const [visibleLayerIds, setVisibleLayerIds] = useState<string[]>([]);
    // To render or not to render, documentDescription
    const [renderTableOrDescription, setRenderTableOrDescription] = useState<
        "Table" | "Description"
    >("Table");
    // Text filter for which documents to render
    const [documentTextFilter, setDocumentTextFilter] = useState("");
    const [documentTableScope, setDocumentTableScope] = useState<"fieldOfView" | "global">("fieldOfView");

    // Initialise map at these coordinates
    const [ centerCoordinates, setCenterCoordinates ] = useState<[number, number]>([4.2, 60.0]);

    const [ hoveredFeatureIds, setHoveredFeatureIds ] = useState<number[]>([]);
    const [ clickedFeatureIds, setClickedFeatureIds ] = useState<number[]>([]);

    const [ bbox, setBbox ] = useState<(catalogEntry: ICatalogEntry) => boolean>(() => (catalogEntry => true));

    const { fetchDocumentCatalogToGeoJSON } = useFetch();

    // Call only once (by setting dependencies array = [])
    useEffect(() => {
        console.log("[LOG] Fetching catalog...");

        // Fetch and set catalog
        fetchDocumentCatalogToGeoJSON(pathCatalog).then((data) => {

            setCatalog(data);

            const _allDocumentLayerIds = Array.from(new Set<string>(data.map(catalogEntry => catalogEntry.Type))).sort();
            
            // Sort as requested _allDocumentLayerIds
            const orderedIdxs = [3, 2, 6, 5, 0, 8, 1, 9, 4, 10, 7];
            const _allDocumentLayerIdsOrdered = [];
            for (let idx of orderedIdxs) {
                _allDocumentLayerIdsOrdered.push(_allDocumentLayerIds[idx])
            }

            setAllDocumentLayerIds(_allDocumentLayerIdsOrdered);
            // Include also Area layers. TODO: Align Areas format with documents format.
            // setVisibleLayerIds([..._allDocumentLayerIdsOrdered, "outlinedAreas", "widenedOutlinedAreas"])
            setVisibleLayerIds([..._allDocumentLayerIdsOrdered])

            console.log("[LOG] catalog was loaded: ", data);
        });

    }, []);

    if (!catalog || !visibleLayerIds) {
    // if (!catalog) {
      return (<Grid container spacing={2} columns={{ xs: 8, sm: 12 }}>
                <Grid
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "100px",
                    }}
                >
                    <CircularProgress />
                </Grid>
            </Grid>);
    }

    const filteredCatalog = getFilteredCatalog(catalog, documentTextFilter, visibleLayerIds);
    const filteredVisibleCatalog = filteredCatalog.filter(bbox);

    return (
            <div style={{ height: "100vh", width: "100vw", position: "absolute" }}>
                <MenuAppBar moduleName={"Metocean Document Portal"} fullWidthPage={true} />

                <ColorLegend colorMap={gebcoColorMap}/>

                <MapConfig
                    catalog={catalog}
                    allDocumentLayerIds={allDocumentLayerIds}
                    visibleLayerIds={visibleLayerIds}
                    filteredVisibleCatalog={filteredVisibleCatalog}
                    hoveredFeatureIds={hoveredFeatureIds}
                    setHoveredFeatureIds={setHoveredFeatureIds}
                    bboxChanged={(bbox) => setBbox(() => bbox)}
                    setClickedFeatureIds={setClickedFeatureIds}
                    setRenderTableOrDescription={setRenderTableOrDescription}
                    centerCoordinates={centerCoordinates}
                />

                <MapLayerMenu
                    allDocumentLayerIds={allDocumentLayerIds}
                    visibleLayerIds={visibleLayerIds}
                    setVisibleLayerIds={setVisibleLayerIds}
                />

                <div className="document-container">
                    {renderTableOrDescription === "Table" ? (
                        <DocumentTable
                            features={documentTableScope === 'global' ? filteredCatalog : filteredVisibleCatalog}
                            documentTextFilter={documentTextFilter}
                            setDocumentTextFilter={setDocumentTextFilter}
                            setRenderTableOrDescription={
                                setRenderTableOrDescription
                            }
                            documentTableScope={documentTableScope}
                            setDocumentTableScope={setDocumentTableScope}
                            setHoveredFeatureIds={setHoveredFeatureIds}
                            setClickedFeatureIds={setClickedFeatureIds}
                            setCenterCoordinates={setCenterCoordinates}
                        />
                    ) : (
                        <DocumentDescription
                            catalogEntries={catalog.filter(catalogEntry => clickedFeatureIds.includes(catalogEntry['id']))}
                            setRenderTableOrDescription={
                                setRenderTableOrDescription
                            }
                        />
                    )}
                </div>
            </div>
    );
}
