import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { useTreeViewModels } from './useTreeViewModels';
import { TREE_VIEW_CORE_PLUGINS } from '../corePlugins';
export function useTreeViewApiInitialization(inputApiRef) {
  const fallbackPublicApiRef = React.useRef({});
  if (inputApiRef) {
    if (inputApiRef.current == null) {
      inputApiRef.current = {};
    }
    return inputApiRef.current;
  }
  return fallbackPublicApiRef.current;
}
export const useTreeView = inParams => {
  const plugins = [...TREE_VIEW_CORE_PLUGINS, ...inParams.plugins];
  const params = plugins.reduce((acc, plugin) => {
    if (plugin.getDefaultizedParams) {
      return plugin.getDefaultizedParams(acc);
    }
    return acc;
  }, inParams);
  const models = useTreeViewModels(plugins, params);
  const instanceRef = React.useRef({});
  const instance = instanceRef.current;
  const publicAPI = useTreeViewApiInitialization(inParams.apiRef);
  const innerRootRef = React.useRef(null);
  const handleRootRef = useForkRef(innerRootRef, inParams.rootRef);
  const [state, setState] = React.useState(() => {
    const temp = {};
    plugins.forEach(plugin => {
      if (plugin.getInitialState) {
        Object.assign(temp, plugin.getInitialState(params));
      }
    });
    return temp;
  });
  const rootPropsGetters = [];
  const contextValue = {
    publicAPI,
    instance: instance,
    rootRef: innerRootRef
  };
  const runPlugin = plugin => {
    const pluginResponse = plugin({
      instance,
      params,
      slots: params.slots,
      slotProps: params.slotProps,
      state,
      setState,
      rootRef: innerRootRef,
      models
    });
    if (pluginResponse.getRootProps) {
      rootPropsGetters.push(pluginResponse.getRootProps);
    }
    if (pluginResponse.publicAPI) {
      Object.assign(publicAPI, pluginResponse.publicAPI);
    }
    if (pluginResponse.instance) {
      Object.assign(instance, pluginResponse.instance);
    }
    if (pluginResponse.contextValue) {
      Object.assign(contextValue, pluginResponse.contextValue);
    }
  };
  plugins.forEach(runPlugin);
  contextValue.runItemPlugins = itemPluginProps => {
    let finalRootRef = null;
    let finalContentRef = null;
    plugins.forEach(plugin => {
      if (!plugin.itemPlugin) {
        return;
      }
      const itemPluginResponse = plugin.itemPlugin({
        props: itemPluginProps,
        rootRef: finalRootRef,
        contentRef: finalContentRef
      });
      if (itemPluginResponse?.rootRef) {
        finalRootRef = itemPluginResponse.rootRef;
      }
      if (itemPluginResponse?.contentRef) {
        finalContentRef = itemPluginResponse.contentRef;
      }
    });
    return {
      contentRef: finalContentRef,
      rootRef: finalRootRef
    };
  };
  const itemWrappers = plugins.map(plugin => plugin.wrapItem).filter(wrapItem => !!wrapItem);
  contextValue.wrapItem = ({
    itemId,
    children
  }) => {
    let finalChildren = children;
    itemWrappers.forEach(itemWrapper => {
      finalChildren = itemWrapper({
        itemId,
        children: finalChildren
      });
    });
    return finalChildren;
  };
  const rootWrappers = plugins.map(plugin => plugin.wrapRoot).filter(wrapRoot => !!wrapRoot);
  contextValue.wrapRoot = ({
    children
  }) => {
    let finalChildren = children;
    rootWrappers.forEach(rootWrapper => {
      finalChildren = rootWrapper({
        children: finalChildren
      });
    });
    return finalChildren;
  };
  const getRootProps = (otherHandlers = {}) => {
    const rootProps = _extends({
      role: 'tree'
    }, otherHandlers, {
      ref: handleRootRef
    });
    rootPropsGetters.forEach(rootPropsGetter => {
      Object.assign(rootProps, rootPropsGetter(otherHandlers));
    });
    return rootProps;
  };
  return {
    getRootProps,
    rootRef: handleRootRef,
    contextValue: contextValue,
    instance: instance
  };
};